import { useLoaderData, useNavigate, useParams } from "react-router"
import { SearchableTripList } from "../../components/searchable-list";
import dayjs from "dayjs";
import { Trip } from "../../api";

export function TripMonthView() {
    const { year, month } = useParams();
    const trips = useLoaderData() as Trip[];
    const navigate = useNavigate();

    return <div>
        <h1 className="text-3xl">{ dayjs(`${year}-${month}-01`).format("MMMM YYYY") }</h1>
        
        <h2 className="text-2xl mt-5">Reisen</h2>

        <SearchableTripList trips={trips} onClick={trip => {
            navigate(`/trip/${ trip.id }`);
        }}/>
    </div>
}