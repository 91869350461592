import { useEffect, useState } from "react";
import { useLoaderData } from "react-router";
import { APIService } from "../../services/api";
import moment from "moment";
import { JourneyChart } from "../../components/journey-chart";
import dayjs from "dayjs";
import { Button } from "@joushx/react-components";
import { Link } from "react-router-dom";
import { AuthService } from "../../services/auth";
import { Trip } from "../../api";

moment.locale("de");

export function TripView() {
    const trip = useLoaderData() as any;
    const [photos, setPhotos] = useState<any>({});

    useEffect(() => {
        if(trip.entries.length === 0) {
            return;
        }

        Promise.all(trip.entries.map((entry: any) => {
            return APIService.getPhotosOfDate(entry.date).then(photos => {
                return {
                    date: entry.date,
                    photos: photos
                }
            }).catch(e => null);
        })).then(responses => {
            let result: {[date: string]: any[]} = {};
            responses.filter(r => r != null).forEach(response => {
                result[response.date] = response.photos;
            })
            return result;
        }).then(result => {
            setPhotos(result);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getGitHubLink(trip: Trip) {
        // @ts-ignore
        let year = trip.entries[0].date.split("-")[0];
        return `https://github.com/joushx/journeyxml/blob/master/data/${year}.yaml`
    }

    return <div>
        <h1 className="text-3xl">{ trip.name }</h1>

        { trip.entries[0].type === "day" && AuthService.getUser() === "johannes" &&
            <Link to={getGitHubLink(trip)}>
                <Button>
                    Bearbeiten
                </Button>
            </Link>
        }

        { trip.entries.map((entry: any) => {

            if (entry.type !== "day") {
                return <div>{ JSON.stringify(entry) }</div>
            }

            let date = entry.date;
            return <div>
                <div className="text-2xl mt-5">{ dayjs(date).format("D. MMMM YYYY") }</div>

                <div className="my-5">
                    { entry.events.map((event: any, index: number) => {
                        if (event.type === "comment") {
                            return <div className="text-xl mt-3">{event.value}</div>
                        }

                        return <div className={index !== 0 ? "mt-3" : ""}>
                            <JourneyChart journey={event}/>
                        </div>
                    })}
                </div>

                <h2>Fotos</h2>

                <div className="grid grid-cols-4 gap-3">
                    { date in photos && photos[date].map((photo: any) => {
                        return <div className="h-auto max-w-full rounded-lg aspect-square">
                            <a href={APIService.getPhotoURL(date, photo)}>
                                <img className="object-cover w-full rounded-lg h-full" src={APIService.getPhotoURL(date, photo)} alt="img"/>
                            </a>
                        </div>
                    })}
                </div>
            </div>
        })}
    </div>
}