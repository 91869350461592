import { useNavigate } from "react-router";
import { YearSelection } from "../../components/year-selection";
import { SearchableTripList } from "../../components/searchable-list";
import { tripsTripsGet } from "../../api";
import { useQuery } from "@tanstack/react-query";

export function TripsListView() {
    const navigate = useNavigate();

    const { isPending, error, data } = useQuery({
        queryKey: ['trips'],
        queryFn: () => tripsTripsGet(),
    })

    if (isPending) {
        return <div>Loading...</div>
    }

    if (error || !data.data) {
        return <div>Fehler</div>
    }

    return <div>

        <h2 className="text-2xl">Jahre</h2>

        <YearSelection start={1969} end={new Date().getFullYear()} onClick={year => {
            navigate(`/trips/years/${year}`);
        }}/>

        <h2 className="text-2xl mt-5">Reisen</h2>

        <SearchableTripList trips={data.data} onClick={trip => {
            navigate(`/trip/${ trip.id }`);
        }}/>
    </div>
}