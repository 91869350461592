import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Trip } from "../api";

export function SearchableTripList(props: SearchableTripListProps) {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [trips, setTrips] = useState<Trip[]>([]);

    useEffect(() => {
        if (searchTerm === "") {
            setTrips(props.trips);
            return;
        }

        setTrips(props.trips.filter(trip => {
            return trip.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        }))
    }, [searchTerm, props.trips]);

    return <div>
        <input type="search" onChange={e => setSearchTerm(e.target.value)} className="w-full bg-transparent rounded p-2 border" placeholder="Suche"></input>

        { trips.map(trip => {
            return <div className="dark:hover:bg-slate-700 hover:bg-slate-300 cursor-pointer rounded p-1">
                <Link to={`/trips/` + trip.id}>{ trip.name }</Link>
            </div>
        })}
    </div>
}

interface SearchableTripListProps {
    trips: Trip[];
    onClick: (Trip: any) => void;
}