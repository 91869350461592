import { useQuery } from "@tanstack/react-query"
import { useParams } from "react-router"
import { APIService } from "../../services/api"

export function LocationView() {
    const { id } = useParams();

    const { isPending, error, data } = useQuery({
        queryKey: ['locations', id],
        queryFn: () => APIService.getLocation(id as string),
    })

    if(isPending) {
        return <div>Loading...</div>
    }

    if(error) {
        return <div>Fehler</div>
    }

    return <div>{ JSON.stringify(data) }</div>
}