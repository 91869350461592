import { Navbar, NavbarItem } from "@joushx/react-components";
import { Outlet, useNavigate } from "react-router";

export function LayoutView() {
    const navigate = useNavigate();

    return <div>
        <Navbar title="Fotos" onTitleClick={() => navigate("/")}>
            <NavbarItem title="Reisen" onClick={() => navigate("/")}/>
            <NavbarItem title="Banhnhöfe" onClick={() => navigate("/stations")}/>
            <NavbarItem title="Fotos" onClick={() => navigate("/photos")}/>
        </Navbar>

        <div className="container mx-auto p-3 md:p-5">
            <Outlet/>
        </div>
    </div>
}