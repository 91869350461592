import { useEffect, useState } from "react";

export function YearSelection(props: YearSelectionProps) {
    const [years, setYears] = useState<number[]>([]);

    useEffect(() => {
        let years = [];
        for(let year = props.start; year <= props.end; year++) {
            years.push(year);
        }
        setYears(years);
    }, [props.start, props.end]);

    return <div className="grid grid-cols-4 md:grid-cols-8 gap-4">
        { years.map(year => {
            return <div onClick={() => props.onClick(year)} className="dark:hover:bg-slate-700 hover:bg-slate-300 cursor-pointer rounded p-1">{year}</div>
        })}
    </div>
}

interface YearSelectionProps {
    start: number;
    end: number;
    onClick: (year: number) => void;
}