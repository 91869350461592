import { useLoaderData } from "react-router";
import { Link } from "react-router-dom";

export function PhotosView() {
    const photos = useLoaderData() as any;

    return <div>
        { photos.map((date: any) => {
            return <div><Link to={`/photos/${date}`}>{date}</Link></div>
        })}
    </div>
}