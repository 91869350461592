import "@joushx/react-components/dist/style.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import {
  createHashRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { LoginView } from './views/auth/login';
import { LoginCallbackView } from './views/auth/callback';
import { authLoader } from './utils/auth-loader';
import { LayoutView } from './views/layout';
import { TripsListView } from './views/trips/TripsListView';
import { APIService } from './services/api';
import { LocationsListView } from "./views/locations/list";
import { PhotosView } from "./views/photos/list";
import { PhotosDateView } from "./views/photos/date";
import { PhotoView } from "./views/photos/single";
import { TripView } from "./views/trips/view";
import { TripYearView } from "./views/trips/year";
import { TripMonthView } from "./views/trips/month";
import dayjs from "dayjs";
import 'dayjs/locale/de-at'
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LocationView } from "./views/locations/view";
import { client } from "./api";
import { AuthService } from "./services/auth";

dayjs.locale('de-at');

client.setConfig({
  baseUrl: "https://api.jmittendorfer.at/journeys/v1"
});

client.interceptors.request.use((request, options) => {
  let token = AuthService.getToken();
  request.headers.set('Authorization', 'Bearer ' + token);
  return request;
});

const router = createHashRouter([
  {
    path: "/",
    element: <LayoutView/>,
    loader: authLoader,
    children: [
      {
        index: true,
        element: <Navigate to="/trips"/>
      },
      {
        path: "/trips",
        children: [
          {
            index: true,
            element: <TripsListView/>,
          },
          {
            path: "years/:year",
            children: [
              {
                index: true,
                loader: async ({params}) => APIService.getTripsByYear(params.year as string).catch(e => []),
                element: <TripYearView/>,
              },
              {
                path: "months/:month",
                loader: async ({params}) => APIService.getTripsByYearAndMonth(params.year as string, params.month as string).catch(e => []),
                element: <TripMonthView/>
              }
            ]
          },
          {
            path: ":id",
            loader: async ({params}) => APIService.getTrip(params.id as string),
            element: <TripView/>,
          }
        ]
      },
      {
        path: "/stations",
        children: [
          {
            index: true,
            element: <LocationsListView/>,
          },
          {
            path: ":id",
            element: <LocationView/>
          }
        ]
      },
      {
        path: "/photos",
        children: [
          {
            index: true,
            loader: async () => APIService.getPhotos(),
            element: <PhotosView/>,
          },
          {
            path: ":date",
            children: [
              {
                index: true,
                loader: async ({params}) => APIService.getPhotosOfDate(params.date as string),
                element: <PhotosDateView/>,
              },
              {
                path: ":id",
                element: <PhotoView/>,
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: "/auth",
    children: [
      {
        path: "/auth",
        children: [
          {
            path: "login",
            element: <LoginView/>
          },
          {
            path: "callback",
            element: <LoginCallbackView/>
          }
        ]
      }
    ]
  },
  {
    path: "*",
    element: <div>404</div>
  }
]);

const queryClient = new QueryClient()

function App() {
  return <QueryClientProvider client={queryClient}>
    <RouterProvider router={router}/>
  </QueryClientProvider>
}

export default App;