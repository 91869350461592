import { useLoaderData, useNavigate, useParams } from "react-router"
import { SearchableTripList } from "../../components/searchable-list";
import { MonthSelection } from "../../components/month-selection";
import { Trip } from "../../api";

export function TripYearView() {
    const { year } = useParams();
    const trips = useLoaderData() as Trip[];
    const navigate = useNavigate();

    return <div>
        <h1 className="text-3xl">{ year }</h1>
        <h2 className="text-2xl">Monate</h2>

        <MonthSelection year={year as string} onClick={(month => {
            navigate(`/trips/years/${ year }/months/${ month }`);
        })}/>

        <h2 className="text-2xl mt-5">Reisen</h2>

        <SearchableTripList trips={trips} onClick={trip => {
            navigate(`/trips/${ trip.id }`);
        }}/>
    </div>
}