import { useQuery } from "@tanstack/react-query";
import { APIService } from "../../services/api";
import { Link } from "react-router-dom";
import { Trip } from "../../api";

export function LocationsListView() {
    const { isPending, error, data } = useQuery({
        queryKey: ['locations'],
        queryFn: () => APIService.getLocations(),
    })

    if (isPending) {
        return  <div>Lade Daten...</div>;
    }

    if (error) {
        return <div>Kann Daten nicht laden 🙁 {error.message} { error.name} </div>
    }

    return <table>
        { Array.from(Object.keys(data)).map((id: any) => {
            let location = data[id];
            return <tr>
                <td>{id}</td>
                <td>{ location.map((trip: Trip) => {
                    return <Link className="ml-5" to={`/trips/${trip.id}`}>{trip.name}</Link>
                }) }</td>
            </tr>
        })}
    </table>
}