import { Trip } from "../api";
import { AuthService } from "./auth"

export class APIService {
    public static getTripsByYearAndMonth(year: string, month: string): Promise<Trip[]> {
        return fetch(process.env.REACT_APP_API_BASE_URL + "trips/date/" + year + "/" + month, {
            headers: {
                "Authorization": "Bearer " + AuthService.getToken(),
                "Accept": "application/json"
            }
        })
        .then(resp => this.handleResponse(resp))
        .then(resp => resp.json());
    }

     public static getTripsByYear(year: string): Promise<any> {
        return fetch(process.env.REACT_APP_API_BASE_URL + "trips/date/" + year, {
            headers: {
                "Authorization": "Bearer " + AuthService.getToken(),
                "Accept": "application/json"
            }
        })
        .then(resp => this.handleResponse(resp))
        .then(resp => resp.json());
    }

    public static getTrip(id: string): Promise<any> {
        return fetch(process.env.REACT_APP_API_BASE_URL + "trips/" + id, {
            headers: {
                "Authorization": "Bearer " + AuthService.getToken(),
                "Accept": "application/json"
            }
        })
        .then(resp => this.handleResponse(resp))
        .then(resp => resp.json());
    }

    public static getLocation(id: string): Promise<any> {
        return fetch(process.env.REACT_APP_API_BASE_URL + "locations/uic/" + id, {
            headers: {
                "Authorization": "Bearer " + AuthService.getToken(),
                "Accept": "application/json"
            }
        })
        .then(resp => this.handleResponse(resp))
        .then(resp => resp.json());
    }

    public static getPhotos(): Promise<any> {
        return fetch(process.env.REACT_APP_API_BASE_URL + "photos", {
            headers: {
                "Authorization": "Bearer " + AuthService.getToken(),
                "Accept": "application/json"
            }
        })
        .then(resp => this.handleResponse(resp))
        .then(resp => resp.json());
    }

    public static getPhoto(date: string, id: string): Promise<any> {
        return fetch(process.env.REACT_APP_API_BASE_URL + "photos/" + date + "/" + id, {
            headers: {
                "Authorization": "Bearer " + AuthService.getToken(),
            }
        });
    }

    public static getPhotosOfDate(date: string): Promise<any> {
        return fetch(process.env.REACT_APP_API_BASE_URL + "photos/" + date, {
            headers: {
                "Authorization": "Bearer " + AuthService.getToken(),
                "Accept": "application/json"
            }
        })
        .then(resp => this.handleResponse(resp))
        .then(resp => resp.json());
    }

    public static getLocations(): Promise<any> {
        return fetch(process.env.REACT_APP_API_BASE_URL + "locations/uic", {
            headers: {
                "Authorization": "Bearer " + AuthService.getToken(),
                "Accept": "application/json"
            }
        })
        .then(resp => this.handleResponse(resp))
        .then(resp => resp.json());
    }

    private static async handleResponse(resp: Response): Promise<Response> {
        if (resp.ok) {
            return resp;
        }

        let data = await resp.json().catch(() => {
            // cannot get json, give generic error
            throw new Error(`Unexpected response code ${resp.status} ${resp.statusText}`);
        });

        if (data["message"]) {
            throw new Error(data["message"]);
        }

        throw new Error("Unknown error");
    }

    public static getPhotoURL(date: string, id: string): string {
        return process.env.REACT_APP_API_BASE_URL + "photos/" + date + "/" + id + "?token=" + AuthService.getToken();
    }
}