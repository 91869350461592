import { useParams } from "react-router";
import { APIService } from "../../services/api";
import { useEffect, useState } from "react";

export function PhotoView() {
    const {date, id} = useParams();
    const [image, setImage] = useState<any>(null);

    useEffect(() => {
        APIService.getPhoto(date as string, id as string).then(data => data.blob()).then(blob => {             
            setImage(URL.createObjectURL(blob));
        })
    }, [date, id])

return <div className="w-full h-view">
        <img src={image} className="object-contain" alt="img"/>
    </div>
}