import dayjs from "dayjs";

export function MonthSelection(props: MonthSelectionProps) {
    return <div className="grid md:grid-cols-8 grid-cols-4 gap-4">
        { [1,2,3,4,5,6,7,8,9,10,11,12].map(month => {
            return <div onClick={() => props.onClick(month)} className="dark:hover:bg-slate-700 hover:bg-slate-300 cursor-pointer rounded p-1">
                { dayjs(`${props.year}-${month}-01`).format("MMMM YYYY") }
            </div>
        })}
    </div>
}

interface MonthSelectionProps {
    year: number | string;
    onClick: (month: number) => void;
}