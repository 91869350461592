import { useLoaderData, useParams } from "react-router";
import { Link } from "react-router-dom";

export function PhotosDateView() {
    const photos = useLoaderData() as any;
    const {date} = useParams();

    return <div>
        { photos.map((file: any) => {
            return <div><Link to={`/photos/${date}/${file}`}>{file}</Link></div>
        })}
    </div>
}