import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthService } from "../../services/auth";
import React from "react";

export function LoginCallbackView() {
    const navigate = useNavigate();
    const [params] = useSearchParams();

    React.useEffect(() => {
        if (params.has("access_token")) {
            AuthService.setToken(params.get("access_token") as string);
            navigate("/");
        }     
    }, [navigate, params]);

    if (params && params.has("error")) {
        return <div>
            <b>{ params.get("error") }: </b>

            { params.has("error_description") && params.get("error_description") }
        </div>
    }

    return <div>?</div>
}