import { useSearchParams } from "react-router-dom";
import { AuthService } from "../../services/auth";

export function LoginView() {
    const [searchParams]= useSearchParams();

    let destination = searchParams.get("destination")
    let loginUrl = AuthService.getLoginURL(destination ? destination : undefined);
    window.location.href = loginUrl;
    console.log(loginUrl);
    return null;
}