import { Link } from "react-router-dom"
import { Badge } from "./badge"

export function JourneyChart(props: JourneyChartProps) {

    function getIcon(mode: string) {
        switch(mode) {
            case "train":
                return "bi-train-front-fill"
            case "bus":
                return "bi-bus-front-fill"
            case "plane":
                return "bi-airplane-fill"
            case "tram":
                return "bi-train-lightrail-front-fill"
            case "bike":
                return "bi-bicycle"
        }

        return ""
    }

    function getColor(mode: string) {
        switch(mode) {
            case "train":
                return "fill-rose-700"
            case "bus":
                return "fill-purple-700"
            case "plane":
                return "fill-gray-700"
            case "tram":
                return "fill-indigo-700"
        }

        return "dark:fill-white"
    }

    return <div className="flex">
        <div style={{width: "30px"}} className="pt-2 flex items-center">
            <i className={`bi ${ getIcon(props.journey.mode) }`}></i>
        </div>
        <div className="flex-1">
            { 
                props.journey.route.map((location: any, index: number) => {

                    let chart = null;
                    if(index === 0) {
                        chart = <svg width={20} height={24}>
                            <circle cx={10} cy={12} r={5} className={ getColor(props.journey.mode) }/>
                            <rect x={8} y={12} width={4} height={13} className={ getColor(props.journey.mode) }/>
                        </svg>
                    }
                    else if(index === props.journey.route.length-1) {
                        chart = <svg width={20} height={24}>
                            <circle cx={10} cy={13} r={5} className={ getColor(props.journey.mode) }/>
                            <rect x={8} y={0} width={4} height={13} className={ getColor(props.journey.mode) }/>
                        </svg>
                    }
                    else {
                        chart = <svg width={20} height={24}>
                            <circle cx={10} cy={13} r={5} className={ getColor(props.journey.mode) }/>
                            <rect x={8} y={0} width={4} height={24} className={ getColor(props.journey.mode) }/>
                        </svg>
                    }

                    return <div className="flex">
                        <div style={{width: "30px"}}>{ chart }</div>
                        <div className="flex-1">
                            { location.name }
                            <span className="ml-2">
                                { location["uic-id"] && <Link to={`/stations/${location["uic-id"]}`}>
                                    <Badge label="UIC" value={location["uic-id"]}/>
                                </Link>}
                            </span>
                        </div>
                    </div>
                        
                })
            }
        </div>
    </div>
}

interface JourneyChartProps {
    journey: any
}