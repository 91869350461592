export function Badge(props: BadgeProps) {
    return <div className="text-xs text-white inline-block">
        <div className="bg-slate-700 inline-block px-1 rounded-l">
            { props.label }
        </div>
        <div className="bg-green-700 inline-block px-1 rounded-r">
            { props.value }
        </div>
    </div>
}

interface BadgeProps {
    label: string;
    value: string;
}